var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"bg",rawName:"v-bg",value:({
    remoteSrc: _vm.imgData.backgroundImg,
    localSrc: require('@/assets/middle-bg.png')
  }),expression:"{\n    remoteSrc: imgData.backgroundImg,\n    localSrc: require('@/assets/middle-bg.png')\n  }"}],staticClass:"container"},[(_vm.isShow)?[(_vm.openRedRepeat)?_c('div',{staticClass:"panel"},[(_vm.succeed)?[_c('p',{staticClass:"p1"},[_vm._v("恭喜您获得")]),_c('div',{staticClass:"money"},[_c('p',{staticClass:"p2"},[_vm._v(_vm._s(_vm.money)),_c('span',{staticClass:"unit"},[_vm._v("元")])])]),_vm._m(0)]:[_c('p',{staticClass:"p1"}),_vm._m(1),_vm._m(2)]],2):_c('div',{staticClass:"panel"},[_c('p',{staticClass:"p1"}),_vm._m(3),_c('p',{staticClass:"p3"},[_vm._v(_vm._s(_vm.errorText))])])]:_c('div',{staticClass:"panel"}),_c('qcode-bar'),_c('draw-number',{staticClass:"draw-number"}),_c('Turntable',{attrs:{"initUrl":_vm.getTurntableImage,"drawUrl":_vm.getDrawRecord,"disabled":_vm.m_disabled,"tracking":_vm.drawTrackingParams},on:{"onStart":_vm.$_onStartTurntable,"drawAfter":_vm.$_drawAfter,"fetchCb":_vm.getFetchData}}),_c('helper')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p3"},[_vm._v("恭喜您获得红包，红包已发放至您的"),_c('br'),_vm._v("微信-零钱内")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"money"},[_c('p',{staticClass:"p2"},[_vm._v("您的红包已发放")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p3"},[_vm._v("恭喜您获得红包，红包已发放至您的"),_c('br'),_vm._v("微信-零钱内")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"money"},[_c('p',{staticClass:"p2"},[_vm._v("抱歉")])])
}]

export { render, staticRenderFns }