<template>
  <div
    class="container"
    v-bg="{
      remoteSrc: imgData.backgroundImg,
      localSrc: require('@/assets/middle-bg.png')
    }"
  >
    <template v-if="isShow">
      <div class="panel" v-if="openRedRepeat">
        <template v-if="succeed">
          <p class="p1">恭喜您获得</p>
          <div class="money">
            <p class="p2">{{ money }}<span class="unit">元</span></p>
          </div>
          <p class="p3">恭喜您获得红包，红包已发放至您的<br />微信-零钱内</p>
        </template>
        <template v-else>
          <p class="p1"></p>
          <div class="money">
            <p class="p2">您的红包已发放</p>
          </div>
          <p class="p3">恭喜您获得红包，红包已发放至您的<br />微信-零钱内</p>
        </template>
      </div>
      <div class="panel" v-else>
        <p class="p1"></p>
        <div class="money">
          <p class="p2">抱歉</p>
        </div>
        <p class="p3">{{ errorText }}</p>
      </div>
    </template>
    <div class="panel" v-else></div>
    <!-- 圈码条 -->
    <qcode-bar></qcode-bar>
    <!-- 剩余抽奖次数 -->
    <draw-number class="draw-number"></draw-number>
    <!-- 转盘 -->
    <Turntable
      :initUrl="getTurntableImage"
      :drawUrl="getDrawRecord"
      :disabled="m_disabled"
      @onStart="$_onStartTurntable"
      @drawAfter="$_drawAfter"
      @fetchCb="getFetchData"
      :tracking="drawTrackingParams"
    />
    <!-- 助手 问题咨询/隐私声明 -->
    <helper></helper>
  </div>
</template>

<script>
import {
  queryTurntableImagesAndSweepstakesRules,
  drawRecord,
  receiveRedPacket,
  receiveRedPacketDouMi,
  receiveRedPacketV3
} from '@/api/index.js'
import { drawMixin } from '@/mixins/index'
import { getIsBack, removeIsBack } from '@/utils/index.js'
import { getSessionStorage, setSessionStorage } from '@/utils/storage.js'
import setting from '@/setting'

export default {
  name: 'Middle',
  mixins: [drawMixin],
  data() {
    return {
      imgData: {},
      drawTrackingParams: {
        userVisit: '3',
        back:
          getSessionStorage('backValue') ||
          function () {
            return 'null'
          }
      },
      money: '0.0',
      openRedRepeat: false,
      succeed: true,
      errorText: '',
      isShow: false
    }
  },
  computed: {
    /* money() {
      return this.$route.query.unitMoney
    } */
  },
  methods: {
    //获取抽奖画面图片和抽奖规则
    getTurntableImage() {
      return queryTurntableImagesAndSweepstakesRules({
        rotaryType: 2
      })
    },
    // 抽奖接口
    getDrawRecord() {
      return drawRecord()
    },
    getFetchData(data) {
      this.imgData = data
      console.log('getFetchData', data)
    },
    // 获取红包
    getRedPacket() {
      let api = {
        fnMaps: {
          base: receiveRedPacket,
          DouMi: receiveRedPacketDouMi,
          V3: receiveRedPacketV3
        },
        getMoney(data) {
          if (redEnvelopeType == 'douMi') {
            return data.unit_money
          } else {
            return data.data.unit_money
          }
        }
      }
      let redEnvelopeType = setting.redEnvelopeApiType || 'base'
      let targetFn = api.fnMaps[redEnvelopeType]
      console.log(redEnvelopeType)

      targetFn({
        prizeId: this.$route.query.prizeId,
        drawLogId: this.$route.query.drawLogId
      })
        .then(({ data }) => {
          this.isShow = true
          if (data.status == 200) {
            this.money = api.getMoney(data)
            this.openRedRepeat = true
          } else if (data.status == 400001) {
            this.succeed = false
            this.openRedRepeat = true
          } else {
            this.errorText = data.msg
            this.openRedRepeat = false
          }
        })
        .catch(() => {
          this.isShow = true
        })
    },
    MIXINpreventBackCb() {
      window.location.reload()
    }
  },
  created() {
    this.getRedPacket()
  },
  beforeRouteEnter(to, from, next) {
    let backFlag = getIsBack()
    console.log('beforeRouteEnter backFlag', backFlag)
    // 如果是通过浏览器(电子奖)返回的重定向到后置转盘
    if (backFlag == 'coupons') {
      removeIsBack()
      next({
        name: 'Back'
      })
    } else {
      setSessionStorage('isMiddlePageEnter', true)
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 1570px;
  padding-top: 33px;
  background-size: 100%;
  background-repeat: no-repeat;
  .panel {
    margin-bottom: 200px;
    padding: 60px 60px 0;
    height: 330px;
    text-align: center;
    .p1 {
      margin-bottom: 10px;
      font-size: 32px;
      color: #333;
      font-weight: bold;
      line-height: 100%;
    }
    .p2 {
      font-size: 83px;
      color: #cd042d;
      font-weight: bold;
      line-height: 100%;
    }
    .p3 {
      font-size: 24px;
      color: #333;
    }
    .money {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .unit {
      align-self: flex-end;
      font-size: 44px;
      font-weight: bold;
      color: #cd042d;
    }
  }
  .draw-number {
    padding: 67px 0 46px;
  }
}
</style>
